import { j as e } from "./jsx-runtime-B6kdoens.js";
import { D as v } from "./Dialog.component-9plNKAVm.js";
import { T as x } from "./Tooltip-Dy7rqQaX.js";
import { A as w } from "./Accordion-D1xtR5eX.js";
import { A as b } from "./Avatar-95vFNu6x.js";
import { B as D } from "./Badge-C0bj4DFU.js";
import { B as j } from "./Button-BrPm3sL-.js";
import "./DropdownMenu-DhgMH6em.js";
import { I as c } from "./Icon-DEu72w0a.js";
import { I as M } from "./Input-BawcWKCk.js";
import { L as Ne } from "./Label-enmFkL7s.js";
import { T as o } from "./Text-DyXxCJgw.js";
import X, { useCallback as F, useEffect as ie, useMemo as _e, useRef as Ce, useState as Q, forwardRef as we } from "react";
import { S as _ } from "./Stack-C1p7gIIp.js";
import "./Switch-CsWZTc7q.js";
import "./Toast-CNCfI-RS.js";
import "./Toggle-Cn0qt44_.js";
import { A as te } from "./AlertDialog.component-JpwtrB5p.js";
import { C as H } from "./Checkbox.component-CNIcq21d.js";
import { D as oe } from "./Drawer.component-CcZv-Ah4.js";
import { S as K } from "./Separator.component-BNi038DN.js";
import { u as ne } from "./useMediaQuery-BLIs2rKV.js";
import { F as Ie } from "./FileIcon.component-dotRenhu.js";
import { A as ke } from "./AddFilesToUserConfirmation.component-CTqbd_LQ.js";
import { g as Ae, a as de, u as De, b as W, c as Oe, P as Be, M as Me, T as Re, D as ze, d as Ge } from "./core.esm-BY4Xl2Is.js";
import { E as Pe } from "./ElementDragOverlay.component-mo-lnRzb.js";
import { c as L } from "./utils-CJ9afRe1.js";
import { S as R } from "./Skeleton-CyNyWRXn.js";
import { H as I } from "./HighlightText.component-BZNRT9Y9.js";
import { L as re } from "./LoanReviewStatusBadge.component-Cs9-r-R-.js";
import { u as Y } from "./index-Dxw4Eym1.js";
import { D as Ue } from "./DropHere.component-yz_RHF_j.js";
import { L as ce, P as Ve } from "./PickLoanToMoveToConfirmation.component-CeYJmrrj.js";
import { P as He } from "./Popover.component-DJZfM-CT.js";
import { C as We } from "./CopyButton.component-Fy-SXdLw.js";
import { S as Qe } from "./ShoeBoxFileRow.component-CccvfgR0.js";
import { A as Xe } from "./ActionAlertDialog.component-DZvfeNL_.js";
import { R as Ke } from "./RenameAlertDialog.component-B-kBBEk4.js";
const he = X.createContext({
  checkedFiles: [],
  totalCheckedFiles: 0,
  displayView: "GRID",
  team: [],
  onUnmount: () => {
  },
  loansFilterQuery: "",
  hideLoanFilter: !1,
  teamUserIds: [],
  onSetLoansFilterQuery: () => {
  },
  droppedFiles: [],
  onCheckTeamUser: () => {
  },
  rejectedFiles: [],
  droppedShoeBoxFiles: [],
  secureUploadPageLink: "",
  loans: [],
  activeMobileTab: "SHOEBOX",
  moveToLoanShoeBoxFiles: [],
  shoeBoxFileToRename: null,
  folders: [],
  stagedFilesForDeletion: [],
  addAllLoan: null,
  totalFiles: 0,
  me: {
    id: "",
    name: "",
    role: "",
    email: "",
    filesCount: 0,
    avatarUrl: "",
    givenName: "",
    initials: ""
  },
  pickLenderLoan: {
    loan: null,
    files: []
  },
  teamIdsSorted: [],
  selectedLoansFilter: "MY_LOANS",
  loadingShoeBoxFiles: !0,
  loadingLoans: !0,
  checkedMoveFilesIds: [],
  checkFilesFromOrToFile: () => {
  },
  openFileDoubleClick: () => {
  },
  goToLoanCreatePage: () => {
  },
  setMoveAllFilesIds: () => {
  },
  renameShoeBoxFile: () => {
  },
  goToLoan: () => {
  },
  moveShoeBoxFilesToLoan: () => {
  },
  setAddAllLoan: () => {
  },
  setPickLenderLoan: () => {
  },
  setMoveToLoanShoeBoxFiles: () => {
  },
  setDroppedShoeBoxFiles: () => {
  },
  openFileInNewTab: () => {
  },
  isLoanLoading: () => !1,
  uploadFilesToShoeBox: () => {
  },
  setLoansFilter: () => {
  },
  setDisplayView: () => {
  },
  unCheckFile: () => {
  },
  setActiveMobileTab: () => {
  },
  setRejectedFiles: () => {
  },
  checkFile: () => {
  },
  setStagedFilesForDeletion: () => {
  },
  setRenameShoeBoxFile: () => {
  },
  unCheckAllFiles: () => {
  },
  isFileChecked: () => !1,
  addSelectedFilesToLoan: () => {
  },
  addAllFilesToLoan: () => {
  },
  uploadFilesToLoan: () => {
  },
  deleteShoeBoxItems: () => {
  },
  cancelUploadingFile: () => {
  }
}), n = {
  SET_LOANS_FILTER_QUERY: "SET_LOANS_FILTER_QUERY",
  TOGGLE_TEAM_USER: "TOGGLE_TEAM_USER",
  SET_TEAM_USER_IDS: "SET_TEAM_USER_IDS",
  ADD_FILE: "ADD_FILE",
  SET_DISPLAY_VIEW: "SET_DISPLAY_VIEW",
  SET_LOANS_FILTER: "SET_LOANS_FILTER",
  SET_TEAM_IDS_SORTED: "SET_TEAM_IDS_SORTED",
  REMOVE_FILE: "REMOVE_FILE",
  SET_LOAN_LOADING_STATE: "SET_LOAN_LOADING_STATE",
  SET_PICK_LENDER_LOAN: "SET_PICK_LENDER_LOAN",
  SET_ADD_ALL_LOAN: "SET_ADD_ALL_LOAN",
  CLEAR_FILES: "CLEAR_FILES",
  SET_CHECKED_MOVE_FILES_IDS: "SET_CHECKED_MOVE_FILES_IDS",
  SET_RENAME_SHOEBOX_FILE: "SET_RENAME_SHOEBOX_FILE",
  SET_STAGED_FILES_FOR_DELETION: "SET_STAGED_FILES_FOR_DELETION",
  SET_DROPPED_FILES: "SET_DROPPED_FILES",
  SET_REJECTED_FILES: "SET_REJECTED_FILES",
  SET_ACTIVE_MOBILE_TAB: "SET_ACTIVE_MOBILE_TAB",
  SET_MOVE_TO_LOAN_SHOEBOX_FILES: "SET_MOVE_TO_LOAN_SHOEBOX_FILES",
  SET_DROPPED_SHOEBOX_FILES: "SET_DROPPED_SHOEBOX_FILES"
}, Ye = {
  loansFilterQuery: "",
  teamIdsSorted: [],
  checkedFiles: [],
  displayView: "GRID",
  stagedFilesForDeletion: [],
  selectedLoansFilter: "MY_LOANS",
  loansLoadingState: {},
  addAllLoan: null,
  pickLenderLoan: {
    loan: null,
    files: []
  },
  teamUserIds: [],
  droppedFiles: [],
  activeMobileTab: "SHOEBOX",
  rejectedFiles: [],
  droppedShoeBoxFiles: [],
  moveToLoanShoeBoxFiles: [],
  shoeBoxFileToRename: null,
  checkedMoveFilesIds: []
}, Je = (s, l) => {
  switch (l.type) {
    case n.ADD_FILE:
      return {
        ...s,
        checkedFiles: [...s.checkedFiles, l.payload]
      };
    case n.REMOVE_FILE:
      return {
        ...s,
        checkedFiles: s.checkedFiles.filter(
          (a) => a.id !== l.payload.id
        )
      };
    case n.TOGGLE_TEAM_USER:
      return {
        ...s,
        teamUserIds: s.teamUserIds.includes(l.payload) ? s.teamUserIds.filter((a) => a !== l.payload) : [...s.teamUserIds, l.payload]
      };
    case n.CLEAR_FILES:
      return {
        ...s,
        checkedFiles: [],
        droppedFiles: [],
        droppedShoeBoxFiles: [],
        moveToLoanShoeBoxFiles: []
      };
    case n.SET_TEAM_USER_IDS:
      return {
        ...s,
        teamUserIds: l.payload
      };
    case n.SET_LOANS_FILTER:
      return {
        ...s,
        selectedLoansFilter: l.payload
      };
    case n.SET_DISPLAY_VIEW:
      return {
        ...s,
        displayView: l.payload
      };
    case n.SET_DROPPED_SHOEBOX_FILES:
      return {
        ...s,
        droppedShoeBoxFiles: l.payload
      };
    case n.SET_CHECKED_MOVE_FILES_IDS:
      return {
        ...s,
        checkedMoveFilesIds: l.payload
      };
    case n.SET_STAGED_FILES_FOR_DELETION:
      return {
        ...s,
        stagedFilesForDeletion: l.payload
      };
    case n.SET_REJECTED_FILES:
      return {
        ...s,
        rejectedFiles: l.payload
      };
    case n.SET_ACTIVE_MOBILE_TAB:
      return {
        ...s,
        activeMobileTab: l.payload
      };
    case n.SET_DROPPED_FILES:
      return {
        ...s,
        droppedFiles: l.payload
      };
    case n.SET_LOANS_FILTER_QUERY:
      return {
        ...s,
        loansFilterQuery: l.payload
      };
    case n.SET_MOVE_TO_LOAN_SHOEBOX_FILES:
      return {
        ...s,
        moveToLoanShoeBoxFiles: l.payload
      };
    case n.SET_ADD_ALL_LOAN:
      return {
        ...s,
        addAllLoan: l.payload
      };
    case n.SET_TEAM_IDS_SORTED:
      return {
        ...s,
        teamIdsSorted: l.payload
      };
    case n.SET_PICK_LENDER_LOAN:
      return {
        ...s,
        pickLenderLoan: l.payload
      };
    case n.SET_RENAME_SHOEBOX_FILE:
      return {
        ...s,
        shoeBoxFileToRename: l.payload
      };
    case n.SET_LOAN_LOADING_STATE:
      return {
        ...s,
        loansLoadingState: {
          ...s.loansLoadingState,
          [l.payload.id]: l.payload.state
        }
      };
    default:
      return s;
  }
}, $e = (s) => {
  const [l, a] = X.useReducer(Je, Ye), t = F((i) => {
    a({ type: n.ADD_FILE, payload: i });
  }, []), m = (i) => {
    a({ type: n.REMOVE_FILE, payload: i });
  }, f = () => {
    a({ type: n.CLEAR_FILES });
  }, u = (i) => {
    a({ type: n.SET_DISPLAY_VIEW, payload: i });
  }, h = (i) => {
    a({ type: n.SET_LOANS_FILTER, payload: i });
  }, d = F(async (i) => {
    a({
      type: n.SET_LOAN_LOADING_STATE,
      payload: {
        id: i.id,
        state: "ADD_ALL"
      }
    });
    try {
      const r = s.folders.reduce((g, y) => g.concat(y.files, y.subFolders.map((A) => A.files).flat()), []).filter((g) => l.checkedMoveFilesIds.includes(g.id));
      i.users.some((g) => g.id === s.me.id) ? (await s.onMoveShoeBoxFilesToLoan(i, s.me.id, r), a({ type: n.CLEAR_FILES })) : a({
        type: n.SET_PICK_LENDER_LOAN,
        payload: {
          loan: i,
          files: r
        }
      });
    } catch (r) {
      console.error(r);
    } finally {
      a({
        type: n.SET_ADD_ALL_LOAN,
        payload: null
      }), a({
        type: n.SET_LOAN_LOADING_STATE,
        payload: {
          id: i.id,
          state: "NONE"
        }
      });
    }
  }, [s, l.checkedMoveFilesIds]), p = F(async (i, r, g) => {
    a({
      type: n.SET_LOAN_LOADING_STATE,
      payload: {
        id: i.id,
        state: "ADD_SELECTED"
      }
    });
    try {
      await s.onMoveShoeBoxFilesToLoan(i, g, r), a({ type: n.CLEAR_FILES });
    } catch (y) {
      console.error(y);
    } finally {
      a({
        type: n.SET_LOAN_LOADING_STATE,
        payload: {
          id: i.id,
          state: "NONE"
        }
      });
    }
  }, [s]), S = F(async (i, r, g) => {
    a({
      type: n.SET_LOAN_LOADING_STATE,
      payload: {
        id: i.id,
        state: "ADD_SELECTED"
      }
    });
    try {
      r === s.me.id && !i.users.some((y) => y.id === r) && await s.onAddMeToLoan(i.id), await s.onMoveShoeBoxFilesToLoan(i, r, g);
    } catch (y) {
      console.error(y);
    } finally {
      a({ type: n.CLEAR_FILES }), a({
        type: n.SET_PICK_LENDER_LOAN,
        payload: {
          loan: null,
          files: []
        }
      }), a({
        type: n.SET_LOAN_LOADING_STATE,
        payload: {
          id: i.id,
          state: "NONE"
        }
      });
    }
  }, [s]), E = F(async (i, r = []) => {
    i != null && i.users.some((g) => g.id === s.me.id) ? await S(i, s.me.id, r) : a({
      type: n.SET_PICK_LENDER_LOAN,
      payload: {
        loan: i,
        files: r
      }
    });
  }, [S, s.me.id]), C = F(
    async (i, r) => {
      i != null && i.users.some((g) => g.id === s.me.id) ? await p(i, r, s.me.id) : (a({
        type: n.SET_DROPPED_SHOEBOX_FILES,
        payload: r
      }), await E(i, r));
    },
    [p, E, s.me.id]
  ), k = F(
    (i) => {
      C(i, l.checkedFiles);
    },
    [l.checkedFiles, C]
  ), O = F(
    (i, r) => r === l.loansLoadingState[i],
    [l.loansLoadingState]
  ), G = F(
    (i, r) => {
      i.users.some((g) => g.id === s.me.id) || (a({ type: n.SET_DROPPED_FILES, payload: r }), E(i));
    },
    [E, s.me.id]
  ), P = F((i) => {
    const r = s.folders.reduce((g, y) => {
      const A = y.files.map((z) => z.id), N = y.subFolders.map((z) => z.files.map((ye) => ye.id)).flat();
      return g.concat(
        A,
        N
      );
    }, []);
    a({
      type: n.SET_CHECKED_MOVE_FILES_IDS,
      payload: r
    }), a({ type: n.SET_ADD_ALL_LOAN, payload: i });
  }, [s.folders]), B = (i) => {
    a({ type: n.SET_MOVE_TO_LOAN_SHOEBOX_FILES, payload: i });
  }, U = F(async (i, r) => {
    await s.onAddFilesToShoeBox(i, r), a({ type: n.SET_REJECTED_FILES, payload: r });
  }, [s]), V = F((i) => {
    s.onCancelUploadingFile(i);
  }, [s]), $ = F(async (i) => {
    await s.onDeleteShoeBoxFiles(i), a({ type: n.CLEAR_FILES });
  }, [s]), Z = F(async (i, r) => {
    s.onRenameShoeBoxFile(i, r), a({ type: n.SET_RENAME_SHOEBOX_FILE, payload: null });
  }, [s]), ve = (i) => {
    a({ type: n.SET_ACTIVE_MOBILE_TAB, payload: i });
  }, pe = (i) => {
    a({ type: n.SET_RENAME_SHOEBOX_FILE, payload: i });
  }, Ee = (i) => {
    a({ type: n.SET_CHECKED_MOVE_FILES_IDS, payload: i });
  }, be = (i) => {
    a({ type: n.SET_STAGED_FILES_FOR_DELETION, payload: i });
  }, q = F((i) => {
    a({ type: n.SET_LOANS_FILTER_QUERY, payload: i });
  }, []), ee = F((i) => {
    a({ type: n.TOGGLE_TEAM_USER, payload: i });
    const r = l.teamUserIds.includes(i) ? l.teamUserIds.filter((g) => g !== i) : [...l.teamUserIds, i];
    s.onTeamFilterChange(r);
  }, [l.teamUserIds]), se = F(() => {
    const i = s.team.map((r) => r.id).sort((r, g) => {
      const y = l.teamUserIds.includes(r), A = l.teamUserIds.includes(g);
      return y && !A ? -1 : !y && A ? 1 : 0;
    });
    a({ type: n.SET_TEAM_IDS_SORTED, payload: i });
  }, [s.team, l.teamUserIds]);
  ie(() => {
    a({ type: n.SET_TEAM_USER_IDS, payload: s.selectedTeamUserIds });
  }, [s.selectedTeamUserIds]);
  const le = F((i) => {
    const r = l.checkedFiles[l.checkedFiles.length - 1];
    if (!r)
      t(i);
    else {
      const g = s.folders.map((N) => N.files).flat().findIndex((N) => N.id === r.id), y = s.folders.map((N) => N.files).flat().findIndex((N) => N.id === i.id);
      s.folders.map((N) => N.files).flat().slice(
        Math.min(g, y),
        Math.max(g, y) + 1
      ).forEach(
        (N) => {
          l.checkedFiles.some((z) => z.id === N.id) || t(N);
        }
      );
    }
  }, [l.checkedFiles, s.folders, t]), ae = s.folders.map((i) => ({
    ...i,
    files: i.files.filter((r) => !l.droppedShoeBoxFiles.some((g) => g.id === r.id))
  })), Te = _e(
    () => ({
      onUnmount: se,
      checkFile: t,
      unCheckFile: m,
      unCheckAllFiles: f,
      setLoansFilter: h,
      setDisplayView: u,
      addSelectedFilesToLoan: k,
      addAllFilesToLoan: d,
      isLoanLoading: O,
      uploadFilesToLoan: G,
      setAddAllLoan: P,
      setDroppedShoeBoxFiles: C,
      setMoveToLoanShoeBoxFiles: B,
      moveShoeBoxFilesToLoan: S,
      setPickLenderLoan: E,
      uploadFilesToShoeBox: U,
      cancelUploadingFile: V,
      goToLoan: s.onGoToLoan,
      setStagedFilesForDeletion: be,
      openFileInNewTab: s.onOpenShoeBoxFileInNewTab,
      deleteShoeBoxItems: $,
      renameShoeBoxFile: Z,
      setRenameShoeBoxFile: pe,
      onCheckTeamUser: ee,
      goToLoanCreatePage: s.onGoToLoanCreatePage,
      setMoveAllFilesIds: Ee,
      onSetLoansFilterQuery: q,
      setRejectedFiles: (i) => a({ type: n.SET_REJECTED_FILES, payload: i }),
      isFileChecked: (i) => l.checkedFiles.some((r) => r.id === i.id),
      openFileDoubleClick: s.onOpenFileDoubleClick,
      checkFilesFromOrToFile: le,
      setActiveMobileTab: ve,
      checkedFiles: l.checkedFiles,
      totalCheckedFiles: l.checkedFiles.length,
      displayView: l.displayView,
      selectedLoansFilter: l.selectedLoansFilter,
      folders: ae,
      loans: s.loans,
      moveToLoanShoeBoxFiles: l.moveToLoanShoeBoxFiles,
      team: s.team,
      addAllLoan: l.addAllLoan,
      me: s.me,
      rejectedFiles: l.rejectedFiles,
      stagedFilesForDeletion: l.stagedFilesForDeletion,
      droppedFiles: l.droppedFiles,
      pickLenderLoan: l.pickLenderLoan,
      shoeBoxFileToRename: l.shoeBoxFileToRename,
      loadingShoeBoxFiles: s.loadingShoeBoxFiles,
      loadingLoans: s.loadingLoans,
      checkedMoveFilesIds: l.checkedMoveFilesIds,
      droppedShoeBoxFiles: l.droppedShoeBoxFiles,
      activeMobileTab: l.activeMobileTab,
      totalFiles: s.totalItems,
      teamIdsSorted: l.teamIdsSorted,
      teamUserIds: l.teamUserIds,
      loansFilterQuery: l.loansFilterQuery,
      hideLoanFilter: s.hideLoanFilter ?? !1,
      secureUploadPageLink: s.secureUploadPageLink
    }),
    [se, t, k, d, O, G, P, C, S, E, U, V, s.onGoToLoan, s.onOpenShoeBoxFileInNewTab, s.onGoToLoanCreatePage, s.onOpenFileDoubleClick, s.loans, s.team, s.me, s.loadingShoeBoxFiles, s.loadingLoans, s.totalItems, s.hideLoanFilter, s.secureUploadPageLink, $, Z, ee, q, le, l.checkedFiles, l.displayView, l.selectedLoansFilter, l.moveToLoanShoeBoxFiles, l.addAllLoan, l.rejectedFiles, l.stagedFilesForDeletion, l.droppedFiles, l.pickLenderLoan, l.shoeBoxFileToRename, l.checkedMoveFilesIds, l.droppedShoeBoxFiles, l.activeMobileTab, l.teamIdsSorted, l.teamUserIds, l.loansFilterQuery, ae]
  );
  return /* @__PURE__ */ e.jsx(he.Provider, { value: Te, children: s.children });
}, T = () => {
  const s = X.useContext(he);
  if (s === void 0)
    throw new Error(
      "useGlobalShoeBox must be used within a GlobalShoeBoxProvider"
    );
  return s;
}, Ze = () => {
  var f, u;
  const s = T(), a = ne("(min-width: 768px)") ? te : oe, t = s.folders.reduce((h, d) => (h.push(d.key), d.subFolders.forEach((p) => h.push(p.key)), h), []), m = s.folders.reduce((h, d) => (h.push(...d.files.map((p) => p.id)), d.subFolders.forEach((p) => {
    h.push(...p.files.map((S) => S.id));
  }), h), []);
  return /* @__PURE__ */ e.jsxs(
    a,
    {
      onOpenChange: (h) => !h && s.setAddAllLoan(null),
      open: !!s.addAllLoan,
      children: [
        /* @__PURE__ */ e.jsx(a.Overlay, {}),
        /* @__PURE__ */ e.jsxs(
          a.Content,
          {
            className: "pt-8 max-w-md",
            children: [
              /* @__PURE__ */ e.jsxs(a.Header, { className: "px-8 items-center flex flex-col justify-center", children: [
                /* @__PURE__ */ e.jsx(c, { name: "BigExclamation", className: "text-black-10" }),
                /* @__PURE__ */ e.jsxs(o, { center: !0, as: "div", className: "pt-6 sm:pt-8", children: [
                  "Are you sure you want to add all items to ",
                  (f = s.addAllLoan) == null ? void 0 : f.shortCode,
                  " ",
                  (u = s.addAllLoan) == null ? void 0 : u.projectName,
                  " ?"
                ] })
              ] }),
              /* @__PURE__ */ e.jsx("div", { className: "px-2 sm:px-8 scrollbar-stable overflow-auto", children: /* @__PURE__ */ e.jsxs("div", { className: "max-h-72 flex gap-3 flex-col h-fit rounded-md border border-1 py-3", children: [
                /* @__PURE__ */ e.jsxs(Ne, { className: "flex items-center gap-2 px-3", children: [
                  /* @__PURE__ */ e.jsx(
                    H,
                    {
                      onCheckedChange: (h) => {
                        h ? s.setMoveAllFilesIds(m) : s.setMoveAllFilesIds([]);
                      },
                      checked: s.checkedMoveFilesIds.length === 0 ? !1 : s.checkedMoveFilesIds.length === s.totalFiles ? !0 : "indeterminate"
                    }
                  ),
                  /* @__PURE__ */ e.jsx(o, { children: "Select all" })
                ] }),
                /* @__PURE__ */ e.jsx(
                  w,
                  {
                    className: "overflow-y-auto scrollbar-stable mx-3 border border-b-1 border-t-1 border-x-0",
                    defaultValue: t,
                    type: "multiple",
                    children: s.folders.filter((h) => h.files.length || h.subFolders.some((d) => d.files.length)).map((h) => /* @__PURE__ */ e.jsx(
                      xe,
                      {
                        folder: h,
                        setMoveAllFilesIds: s.setMoveAllFilesIds,
                        checkedMoveFilesIds: s.checkedMoveFilesIds
                      },
                      h.key
                    ))
                  }
                )
              ] }) }),
              /* @__PURE__ */ e.jsx(K, {}),
              /* @__PURE__ */ e.jsxs(te.Footer, { children: [
                /* @__PURE__ */ e.jsx(
                  j,
                  {
                    onClick: () => s.setAddAllLoan(null),
                    className: "w-24",
                    variant: "outline",
                    children: "Cancel"
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  j,
                  {
                    className: "w-24",
                    disabled: s.checkedMoveFilesIds.length === 0,
                    onClick: () => s.addAllLoan && s.addAllFilesToLoan(s.addAllLoan),
                    children: "Add all"
                  }
                )
              ] })
            ]
          }
        )
      ]
    }
  );
}, xe = (s) => /* @__PURE__ */ e.jsxs(
  w.Item,
  {
    value: s.folder.key,
    title: s.folder.name,
    children: [
      /* @__PURE__ */ e.jsx(w.Trigger, { asChild: !0, className: "cursor-pointer", children: /* @__PURE__ */ e.jsxs(_, { row: !0, space: "sm", children: [
        /* @__PURE__ */ e.jsx(
          D,
          {
            variant: "warning",
            className: "p-0 inline-flex w-5 h-5 items-center justify-center",
            children: s.folder.filesCount
          }
        ),
        /* @__PURE__ */ e.jsx(o, { size: "sm", truncate: !0, children: s.folder.senderName ?? s.folder.senderEmailAddress })
      ] }, s.folder.key) }),
      /* @__PURE__ */ e.jsxs(w.Content, { className: "pl-2", children: [
        s.folder.subFolders.map((l) => /* @__PURE__ */ e.jsx(
          xe,
          {
            folder: l,
            setMoveAllFilesIds: s.setMoveAllFilesIds,
            checkedMoveFilesIds: s.checkedMoveFilesIds
          },
          l.key
        )),
        /* @__PURE__ */ e.jsx(_, { space: "md", children: s.folder.files.map((l) => /* @__PURE__ */ e.jsxs(
          _,
          {
            row: !0,
            space: "sm",
            className: "items-center",
            children: [
              /* @__PURE__ */ e.jsx(
                H,
                {
                  onCheckedChange: (a) => {
                    s.setMoveAllFilesIds(
                      a ? [...s.checkedMoveFilesIds, l.id] : s.checkedMoveFilesIds.filter(
                        (t) => t !== l.id
                      )
                    );
                  },
                  checked: s.checkedMoveFilesIds.includes(l.id)
                }
              ),
              /* @__PURE__ */ e.jsx(Ie, { fileName: l.documentName }),
              /* @__PURE__ */ e.jsx(o, { size: "sm", truncate: !0, children: l.title })
            ]
          },
          l.id
        )) })
      ] })
    ]
  },
  s.folder.key
), qe = () => {
  const s = T();
  return /* @__PURE__ */ e.jsx(
    ke,
    {
      open: !!s.pickLenderLoan.loan,
      onOpenChange: (l) => {
        l || (s.setPickLenderLoan(null, []), s.setDroppedShoeBoxFiles(null, []));
      },
      loan: s.pickLenderLoan.loan,
      items: s.pickLenderLoan.files,
      me: s.me,
      onConfirm: s.moveShoeBoxFilesToLoan
    }
  );
};
function es(s, l, a) {
  const t = {
    ...s
  };
  return l.top + s.y <= a.top ? t.y = a.top - l.top : l.bottom + s.y >= a.top + a.height && (t.y = a.top + a.height - l.bottom), l.left + s.x <= a.left ? t.x = a.left - l.left : l.right + s.x >= a.left + a.width && (t.x = a.left + a.width - l.right), t;
}
const ss = (s) => {
  let {
    transform: l,
    draggingNodeRect: a,
    windowRect: t
  } = s;
  return !a || !t ? l : es(l, a, t);
}, ls = (s) => {
  let {
    activatorEvent: l,
    draggingNodeRect: a,
    transform: t
  } = s;
  if (a && l) {
    const m = Ae(l);
    if (!m)
      return t;
    const f = m.x - a.left, u = m.y - a.top;
    return {
      ...t,
      x: t.x + f - a.width / 2,
      y: t.y + u - a.height / 2
    };
  }
  return t;
}, as = () => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
  /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-48" }) }),
  /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-48" }) }),
  /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-48" }) })
] }), ts = (s) => {
  const l = T(), { setNodeRef: a, isOver: t } = de({
    id: s.loan.id,
    data: s.loan
  });
  return /* @__PURE__ */ e.jsxs(
    "article",
    {
      ref: a,
      "data-ui": t ? "active" : "",
      className: "min-h-48 bg-white rounded-md border data-active:border-blue-100 border-gray-neutral-80 overflow-hidden p-4 relative",
      children: [
        t && /* @__PURE__ */ e.jsx(
          o,
          {
            size: "sm",
            weight: "medium",
            as: "div",
            "data-ui": t ? "active" : "",
            className: "items-center justify-center flex p-4 opacity-0 data-active:opacity-100 transition-opacity will-change-auto absolute inset-0 z-10 bg-blue-50 bg-opacity-70 text-blue-100",
            children: /* @__PURE__ */ e.jsx("div", { className: "p-2 rounded-md bg-blue-50", children: "DROP FILES HERE" })
          }
        ),
        /* @__PURE__ */ e.jsxs("div", { children: [
          /* @__PURE__ */ e.jsx(
            o,
            {
              size: "xs",
              className: "rounded inline-flex px-1 text-gray-neutral-70 border border-gray-neutral-40",
              children: /* @__PURE__ */ e.jsx(I, { text: s.loan.shortCode, highlightText: String(s.highlightText) })
            }
          ),
          /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              o,
              {
                as: "div",
                truncate: !0,
                size: "sm",
                weight: "medium",
                className: "mt-3",
                children: /* @__PURE__ */ e.jsx(I, { text: s.loan.projectName, highlightText: String(s.highlightText) })
              }
            ) }),
            /* @__PURE__ */ e.jsx(
              x.Content,
              {
                className: "w-60 break-all",
                align: "start",
                side: "bottom",
                alignOffset: 0,
                children: s.loan.projectName
              }
            )
          ] }),
          /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(o, { as: "div", truncate: !0, size: "xs", variant: "secondary", className: "mt-2 overflow-hidden max-h-4 whitespace-nowrap", children: [
              /* @__PURE__ */ e.jsx("div", { dangerouslySetInnerHTML: { __html: s.loan.loanPurpose } }),
              " "
            ] }) }),
            /* @__PURE__ */ e.jsx(
              x.Content,
              {
                className: "w-60 break-all",
                align: "start",
                side: "bottom",
                alignOffset: 0,
                children: /* @__PURE__ */ e.jsx("div", { dangerouslySetInnerHTML: { __html: s.loan.loanPurpose } })
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ e.jsxs("div", { className: "flex gap-3 mt-3 flex-1", children: [
          /* @__PURE__ */ e.jsxs(D, { variant: "secondary", className: "inline-flex gap-2 truncate", children: [
            /* @__PURE__ */ e.jsx(c, { name: "Bag", width: 15, height: 15 }),
            /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(I, { text: s.loan.loanType, highlightText: String(s.highlightText) }) })
          ] }),
          /* @__PURE__ */ e.jsx(re, { status: s.loan.loanStatus.status, children: /* @__PURE__ */ e.jsx(I, { text: s.loan.loanStatus.label, highlightText: String(s.highlightText) }) })
        ] }),
        /* @__PURE__ */ e.jsxs("footer", { className: "flex gap-4 mt-6", children: [
          l.totalFiles > 0 && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx(
              j,
              {
                loading: l.isLoanLoading(s.loan.id, "ADD_ALL"),
                onClick: () => l.setAddAllLoan(s.loan),
                variant: "outline",
                size: "sm",
                disabled: l.totalFiles === 0,
                className: "text-blue-100 bg-white truncate",
                children: "Add All"
              }
            ),
            /* @__PURE__ */ e.jsx(
              j,
              {
                disabled: l.checkedFiles.length === 0,
                loading: l.isLoanLoading(s.loan.id, "ADD_SELECTED"),
                onClick: () => l.addSelectedFilesToLoan(s.loan),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate",
                children: "Add Selected"
              }
            )
          ] }),
          /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(v.Close, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
              j,
              {
                onClick: () => l.goToLoan(s.loan.id),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate",
                children: [
                  /* @__PURE__ */ e.jsx(c, { name: "Handbag", className: "shrink-0", width: 20, height: 20, strokeWidth: 1.5 }),
                  /* @__PURE__ */ e.jsx(
                    "span",
                    {
                      "data-ui": l.totalFiles === 0 ? "active" : "",
                      className: "hidden data-active:inline-block lg:data-active:inline-block",
                      children: "Go to Loan"
                    }
                  )
                ]
              }
            ) }) }),
            /* @__PURE__ */ e.jsx(x.Content, { align: "center", side: "bottom", alignOffset: 0, children: "Go to Loan" })
          ] })
        ] })
      ]
    },
    s.loan.id
  );
}, is = (s) => {
  const l = T(), { setNodeRef: a, isOver: t } = de({
    id: s.loan.id,
    data: s.loan
  }), m = l.folders.some((f) => f.files.some((u) => !u.uploading));
  return /* @__PURE__ */ e.jsxs(
    "article",
    {
      ref: a,
      "data-ui": t ? "active" : "",
      className: "min-h-16 bg-white gap-4 grid grid-cols-6 lg:flex lg:flex-row items-center rounded-md border data-active:border-blue-100 border-gray-neutral-80 overflow-hidden p-4 relative",
      children: [
        t && /* @__PURE__ */ e.jsx(
          o,
          {
            size: "sm",
            weight: "medium",
            as: "div",
            "data-ui": t ? "active" : "",
            className: "items-center justify-center z-10 flex p-4 opacity-0 data-active:opacity-100 transition-opacity will-change-auto absolute inset-0 bg-blue-50 bg-opacity-70 text-blue-100",
            children: /* @__PURE__ */ e.jsx("div", { className: "p-2 rounded-md bg-blue-50", children: "DROP FILES HERE" })
          }
        ),
        /* @__PURE__ */ e.jsx(o, { size: "sm", weight: "medium", className: "shrink-0 col-span-2", children: /* @__PURE__ */ e.jsx(I, { text: s.loan.shortCode, highlightText: String(s.highlightText) }) }),
        /* @__PURE__ */ e.jsx(
          o,
          {
            size: "sm",
            weight: "medium",
            className: "flex-1 order-4 lg:order-2 col-span-3",
            truncate: !0,
            children: /* @__PURE__ */ e.jsx(I, { text: s.loan.projectName, highlightText: String(s.highlightText) })
          }
        ),
        /* @__PURE__ */ e.jsxs("div", { className: "col-span-4 flex justify-end lg:justify-start  order-2 lg:order-3 items-center gap-4", children: [
          /* @__PURE__ */ e.jsxs(D, { variant: "secondary", className: "inline-flex gap-2 w-auto min-w-0 max-w-40", children: [
            /* @__PURE__ */ e.jsx(c, { name: "Bag", width: 15, height: 15, className: "shrink-0" }),
            /* @__PURE__ */ e.jsx("span", { className: "truncate", children: /* @__PURE__ */ e.jsx(I, { text: s.loan.loanType, highlightText: String(s.highlightText) }) })
          ] }),
          /* @__PURE__ */ e.jsx(
            re,
            {
              status: s.loan.loanStatus.status,
              children: /* @__PURE__ */ e.jsx(I, { text: s.loan.loanStatus.label, highlightText: String(s.highlightText) })
            }
          )
        ] }),
        /* @__PURE__ */ e.jsxs("div", { className: "order-5 col-span-3 justify-end flex flex-row lg:flex items-center gap-2 lg:gap-4 sm:order-4", children: [
          l.totalFiles > 0 && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx(
              j,
              {
                disabled: !m,
                loading: l.isLoanLoading(s.loan.id, "ADD_ALL"),
                onClick: () => l.setAddAllLoan(s.loan),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate",
                children: "Add All"
              }
            ),
            /* @__PURE__ */ e.jsx(
              j,
              {
                disabled: l.checkedFiles.length === 0,
                loading: l.isLoanLoading(s.loan.id, "ADD_SELECTED"),
                onClick: () => l.addSelectedFilesToLoan(s.loan),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate px-2",
                children: "Add Selected"
              }
            )
          ] }),
          /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(v.Close, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
              j,
              {
                onClick: () => l.goToLoan(s.loan.id),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate flex items-center gap-1",
                children: [
                  /* @__PURE__ */ e.jsx(c, { name: "Handbag", className: "shrink-0", width: 20, height: 20, strokeWidth: 1.5 }),
                  /* @__PURE__ */ e.jsx(
                    "span",
                    {
                      "data-ui": l.totalFiles === 0 ? "active" : "",
                      className: "hidden data-active:inline-block lg:data-active:inline-block",
                      children: "Go to Loan"
                    }
                  )
                ]
              }
            ) }) }),
            /* @__PURE__ */ e.jsx(
              x.Content,
              {
                align: "start",
                side: "bottom",
                alignOffset: 0,
                children: "Go to Loan"
              }
            )
          ] })
        ] })
      ]
    },
    s.loan.id
  );
}, os = "global-shoebox-body-scrollable-target", ns = (s, l) => s.projectName.toLowerCase().includes(l.toLowerCase()) || s.shortCode.toLowerCase().includes(l.toLowerCase()) || s.loanStatus.label.toLowerCase().includes(l.toLowerCase()) || s.loanType.toLowerCase().includes(l.toLowerCase()), ds = () => {
  const s = T(), l = s.displayView === "GRID" ? ts : is, a = s.loans.filter((t) => s.selectedLoansFilter === "MY_LOANS" ? t.iAmAdded : s.selectedLoansFilter === "ALL_LOANS").filter((t) => ns(t, s.loansFilterQuery));
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      "data-ui": s.activeMobileTab === "LOANS" ? "active" : "",
      id: os,
      className: L(
        "hidden data-active:flex sm:flex flex-1 flex-col [&>div]:flex-1 py-10 px-6 overflow-y-auto scrollbar-stable",
        {
          "lg:grid-cols-2 xl:grid-cols-3": s.displayView === "GRID"
        }
      ),
      children: [
        !s.loadingLoans && a.length === 0 && /* @__PURE__ */ e.jsxs(
          _,
          {
            className: "flex-1 items-center justify-center",
            space: "md",
            children: [
              /* @__PURE__ */ e.jsx(c, { name: "BigExclamation", className: "text-black-20", width: 64, height: 64 }),
              /* @__PURE__ */ e.jsx(o, { size: "sm", children: "No results match your search" })
            ]
          }
        ),
        s.loadingLoans || a.length > 0 && /* @__PURE__ */ e.jsxs("div", { className: L("grid grid-cols-1 gap-5 content-start flex-1", {
          "lg:grid-cols-2 xl:grid-cols-3": s.displayView === "GRID"
        }), children: [
          s.loadingLoans && /* @__PURE__ */ e.jsx(as, {}),
          a.map((t) => /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(
            l,
            {
              highlightText: s.loansFilterQuery,
              loan: t
            },
            t.id
          ) }, t.id))
        ] })
      ]
    }
  );
}, J = {
  "image/*": [],
  "video/*": [],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/zip": [".zip"],
  "application/x-rar-compressed": [".rar"],
  "text/plain": [".txt"],
  "text/csv": [".csv"],
  "application/json": [".json"],
  "application/xml": [".xml"]
}, me = () => {
  const s = T(), l = F(
    (t, m) => {
      s.uploadFilesToShoeBox(t, m.map((f) => f.file));
    },
    [s]
  ), a = Y({
    accept: J,
    onDrop: l,
    multiple: !0
  });
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      role: "button",
      tabIndex: 0,
      ...a.getRootProps(),
      onClick: (t) => t.preventDefault(),
      onKeyUp: (t) => t.preventDefault(),
      className: "bg-white relative px-4 py-5 flex-col gap-3 w-full border-t border-gray-neutral-80 flex items-center justify-center",
      children: [
        /* @__PURE__ */ e.jsx(
          "input",
          {
            ...a.getInputProps(),
            id: "shoebox-footer-dropdown-target"
          }
        ),
        /* @__PURE__ */ e.jsx(Ue, { active: a.isDragActive }),
        /* @__PURE__ */ e.jsx("div", { className: "flex-1", children: /* @__PURE__ */ e.jsx(b, { size: "xs", rounded: "md", children: /* @__PURE__ */ e.jsx(b.Fallback, { className: "p-0  ", children: /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }) }),
        /* @__PURE__ */ e.jsxs("div", { className: "text-center flex flex-col gap-3", children: [
          /* @__PURE__ */ e.jsxs(o, { size: "sm", as: "div", children: [
            "Drag and Drop or",
            " ",
            /* @__PURE__ */ e.jsxs(
              o,
              {
                onClick: a.open,
                size: "sm",
                as: "span",
                variant: "blue",
                className: "underline cursor-pointer",
                children: [
                  "Browse my Device",
                  " "
                ]
              }
            ),
            "to upload"
          ] }),
          /* @__PURE__ */ e.jsx(o, { size: "xs", as: "div", variant: "secondary", children: "Any file type supported. Max size 25 MB" })
        ] })
      ]
    }
  );
};
me.displayName = "GlobalShoeFooter";
const rs = (s) => {
  const l = s.displayView === "GRID", a = s.displayView === "LIST";
  return /* @__PURE__ */ e.jsxs("div", { className: "rounded-md lg:border-gray-neutral-80 lg:border flex", children: [
    /* @__PURE__ */ e.jsxs(
      j,
      {
        onClick: () => s.onChange("GRID"),
        name: "intent",
        variant: "ghost",
        type: "submit",
        size: "sm",
        value: "GRID",
        className: L(
          "lg:rounded-none flex gap-1 font-normal",
          {
            "text-gray-neutral-70": a,
            "lg:bg-gray-neutral-32": l
          }
        ),
        children: [
          /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "ViewGrid", width: 16, height: 16 }),
          /* @__PURE__ */ e.jsx(o, { className: "hidden lg:inline-flex", size: "sm", children: "Card View" })
        ]
      }
    ),
    /* @__PURE__ */ e.jsx(K, { orientation: "vertical", className: "hidden sm:inline-flex flex-1 bg-gray-neutral-80" }),
    /* @__PURE__ */ e.jsxs(
      j,
      {
        onClick: () => s.onChange("LIST"),
        name: "intent",
        variant: "ghost",
        type: "submit",
        size: "sm",
        value: "LIST",
        className: L(
          "lg:rounded-none flex gap-1 font-normal",
          {
            "text-gray-neutral-70": l,
            "lg:bg-gray-neutral-32": a
          }
        ),
        children: [
          /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "Table2Columns", width: 16, height: 16 }),
          /* @__PURE__ */ e.jsx(o, { className: "hidden lg:inline-flex", size: "sm", children: "Table View" })
        ]
      }
    )
  ] });
}, ue = () => {
  const s = T();
  return /* @__PURE__ */ e.jsxs("div", { className: "hidden sm:flex sm:flex-col lg:flex-row bg-white-neutral sm:bg-black-10 lg:bg-white p-4 w-full lg:border-b lg:border-gray-neutral-80 items-center", children: [
    /* @__PURE__ */ e.jsxs("div", { className: "flex justify-between w-full", children: [
      /* @__PURE__ */ e.jsx("div", { className: "shrink-0", children: s.hideLoanFilter ? /* @__PURE__ */ e.jsx(o, { size: "sm", children: "My Loans" }) : /* @__PURE__ */ e.jsx(
        ce,
        {
          active: s.selectedLoansFilter,
          onFilterChange: s.setLoansFilter
        }
      ) }),
      /* @__PURE__ */ e.jsx("div", { className: "pr-2 flex-1 flex justify-end", children: /* @__PURE__ */ e.jsxs(
        M,
        {
          wrapClassName: "w-full max-w-80 bg-white",
          value: s.loansFilterQuery,
          onChange: (l) => s.onSetLoansFilterQuery(l.target.value),
          placeholder: "Search loan id, title, type or status",
          variant: "sm",
          children: [
            /* @__PURE__ */ e.jsx(M.Prefix, { children: /* @__PURE__ */ e.jsx(c, { name: "Search", width: 18, height: 18, strokeWidth: 1.5 }) }),
            /* @__PURE__ */ e.jsx(M.Suffix, { children: /* @__PURE__ */ e.jsx(
              j,
              {
                "data-hidden": !s.loansFilterQuery,
                onClick: () => s.onSetLoansFilterQuery(""),
                variant: "ghost",
                size: "sm",
                className: "px-1",
                children: /* @__PURE__ */ e.jsx(c, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
              }
            ) })
          ]
        }
      ) })
    ] }),
    /* @__PURE__ */ e.jsxs("div", { className: "flex gap-4 justify-end items-center shrink-0 w-full lg:w-auto", children: [
      /* @__PURE__ */ e.jsx(
        rs,
        {
          displayView: s.displayView,
          onChange: s.setDisplayView
        }
      ),
      !s.hideLoanFilter && /* @__PURE__ */ e.jsx(v.Close, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
        j,
        {
          onClick: () => s.goToLoanCreatePage(),
          size: "sm",
          className: "gap gap-1 fixed z-10 right-[19px] rounded-full lg:rounded-md bottom-[157px] lg:relative lg:right-auto truncate lg:bottom-auto",
          children: [
            /* @__PURE__ */ e.jsx(c, { name: "Plus", strokeWidth: 1.5, width: 16, height: 16 }),
            " Start loan"
          ]
        }
      ) })
    ] })
  ] });
};
ue.displayName = "GlobalShoeHeader";
const ge = () => {
  const s = Ce(null), [l, a] = Q(""), t = T(), m = t.team.filter((d) => d.name.toLowerCase().includes(l.toLowerCase())).sort((d, p) => {
    if (t.teamIdsSorted.length > 0) {
      const S = t.teamIdsSorted.indexOf(d.id), E = t.teamIdsSorted.indexOf(p.id);
      if (S !== -1 && E !== -1)
        return S - E;
      if (S !== -1)
        return -1;
      if (E !== -1)
        return 1;
    }
    return d.name.localeCompare(p.name);
  }), f = t.me.name.toLowerCase().includes(l.toLowerCase()), h = ne("(min-width: 768px)") ? He : oe;
  return /* @__PURE__ */ e.jsxs(
    h,
    {
      onOpenChange: () => t.onUnmount(),
      children: [
        /* @__PURE__ */ e.jsx(h.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
          j,
          {
            className: "px-1",
            type: "button",
            variant: "ghost",
            size: "sm",
            children: /* @__PURE__ */ e.jsx(c, { name: "NavArrowDown", width: 18, height: 18, strokeWidth: 1.5 })
          }
        ) }),
        /* @__PURE__ */ e.jsxs(
          h.Content,
          {
            align: "start",
            side: "bottom",
            alignOffset: 0,
            className: "md:w-60 h-96 md:h-80 px-0 border-gray-neutral-80 flex flex-col",
            children: [
              /* @__PURE__ */ e.jsxs(
                M,
                {
                  wrapClassName: "mx-2",
                  variant: "sm",
                  value: l,
                  onChange: (d) => a(d.target.value),
                  ref: s,
                  placeholder: "Search",
                  children: [
                    /* @__PURE__ */ e.jsx(M.Prefix, { children: /* @__PURE__ */ e.jsx(c, { name: "Search", className: "mr-2 shrink-0 text-gray-neutral-100", width: 18, height: 18 }) }),
                    l && /* @__PURE__ */ e.jsx(M.Suffix, { children: /* @__PURE__ */ e.jsx(
                      j,
                      {
                        onClick: () => {
                          a(""), s != null && s.current && s.current.focus();
                        },
                        size: "sm",
                        className: "px-1",
                        variant: "ghost",
                        children: /* @__PURE__ */ e.jsx(c, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
                      }
                    ) })
                  ]
                }
              ),
              /* @__PURE__ */ e.jsxs(
                _,
                {
                  space: "sm",
                  className: "flex-col px-2 mt-2 scrollbar-stable py-3 flex-1 overflow-y-scroll",
                  children: [
                    !f && !m.length && /* @__PURE__ */ e.jsxs("div", { className: "flex items-center justify-center flex-col h-64 gap-4 ", children: [
                      /* @__PURE__ */ e.jsx(c, { name: "BigExclamation", width: 64, height: 64, className: "text-black-10" }),
                      /* @__PURE__ */ e.jsx(o, { size: "sm", children: "User not found" })
                    ] }),
                    f && /* @__PURE__ */ e.jsxs(
                      _,
                      {
                        row: !0,
                        className: "gap-2",
                        children: [
                          /* @__PURE__ */ e.jsx(
                            H,
                            {
                              onCheckedChange: () => t.onCheckTeamUser(t.me.id),
                              checked: t.teamUserIds.includes(t.me.id),
                              size: "sm",
                              className: "bg-white"
                            }
                          ),
                          /* @__PURE__ */ e.jsxs(b, { size: "xsm", children: [
                            /* @__PURE__ */ e.jsx(b.Fallback, { className: "bg-blue-100 text-white", children: t.me.initials }),
                            /* @__PURE__ */ e.jsx(b.Image, { src: t.me.avatarUrl })
                          ] }),
                          /* @__PURE__ */ e.jsxs(
                            o,
                            {
                              as: "div",
                              size: "sm",
                              truncate: !0,
                              children: [
                                t.me.givenName,
                                " ",
                                /* @__PURE__ */ e.jsx(o, { size: "sm", as: "span", variant: "secondary", children: "(me)" })
                              ]
                            }
                          ),
                          t.me.filesCount > 0 && /* @__PURE__ */ e.jsx(D, { variant: "warning", circle: !0, className: "w-5 text-xxs aspect-square h-5 flex items-center justify-center", children: t.me.filesCount })
                        ]
                      }
                    ),
                    m.length > 0 && /* @__PURE__ */ e.jsxs(_, { space: "sm", children: [
                      /* @__PURE__ */ e.jsx(o, { size: "sm", children: "Team Shoebox" }),
                      m.map((d) => /* @__PURE__ */ e.jsxs(
                        _,
                        {
                          row: !0,
                          space: "sm",
                          className: "items-center hover:bg-gray-neutral-30 rounded-md min-h-9 px-2 py-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              H,
                              {
                                onCheckedChange: () => t.onCheckTeamUser(d.id),
                                checked: t.teamUserIds.includes(d.id),
                                size: "sm",
                                className: "bg-white"
                              }
                            ),
                            /* @__PURE__ */ e.jsxs(b, { size: "xsm", children: [
                              /* @__PURE__ */ e.jsx(b.Fallback, { className: "bg-blue-100 text-white", children: d.initials }),
                              /* @__PURE__ */ e.jsx(b.Image, { src: d.avatarUrl })
                            ] }),
                            /* @__PURE__ */ e.jsx(o, { size: "sm", truncate: !0, children: d.name }),
                            d.filesCount > 0 && /* @__PURE__ */ e.jsx(D, { variant: "warning", circle: !0, className: "w-5 text-xxs aspect-square h-5 flex items-center justify-center", children: d.filesCount })
                          ]
                        },
                        d.id
                      ))
                    ] })
                  ]
                }
              )
            ]
          }
        )
      ]
    }
  );
}, cs = () => {
  const s = T();
  return /* @__PURE__ */ e.jsxs("div", { className: "sm:hidden", children: [
    /* @__PURE__ */ e.jsx("div", { className: "p-3", children: /* @__PURE__ */ e.jsx(v.Close, { asChild: !0, children: /* @__PURE__ */ e.jsxs(j, { size: "sm", variant: "secondary", children: [
      /* @__PURE__ */ e.jsx(c, { name: "NavArrowLeft", width: 18, height: 18, strokeWidth: 1.5 }),
      /* @__PURE__ */ e.jsx(o, { size: "sm", className: "hidden sm:inline-flex", children: "Back" })
    ] }) }) }),
    /* @__PURE__ */ e.jsxs("div", { className: "flex flex-1 text-center border-b border-gray-neutral-80", children: [
      /* @__PURE__ */ e.jsxs(
        o,
        {
          onClick: () => s.setActiveMobileTab("SHOEBOX"),
          as: "div",
          variant: s.activeMobileTab === "SHOEBOX" ? "blue" : "primary",
          className: L("flex-1 flex items-center justify-center py-2 border-b-2", {
            "border-blue-100": s.activeMobileTab === "SHOEBOX",
            "border-transparent": s.activeMobileTab !== "SHOEBOX"
          }),
          children: [
            "My Shoebox ",
            /* @__PURE__ */ e.jsxs(v, { children: [
              /* @__PURE__ */ e.jsx(v.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(j, { size: "sm", variant: "ghost", children: /* @__PURE__ */ e.jsx(c, { name: "InfoEmpty", width: 16, height: 16, strokeWidth: 1.5 }) }) }),
              /* @__PURE__ */ e.jsxs(v.Content, { className: "max-w-xs p-4 rounded-md", children: [
                /* @__PURE__ */ e.jsxs(_, { row: !0, space: "sm", className: "items-center", children: [
                  /* @__PURE__ */ e.jsx(b, { size: "xs", className: "rounded-md", children: /* @__PURE__ */ e.jsx(b.Fallback, { className: "p-0 bg-blue-100 text-white ", children: /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }),
                  /* @__PURE__ */ e.jsx(o, { size: "sm", weight: "medium", children: "Global Shoebox" })
                ] }),
                /* @__PURE__ */ e.jsx("div", { className: "flex flex-col gap-2", children: /* @__PURE__ */ e.jsx(o, { variant: "inherit", size: "sm", children: "Upload and Select to Move or Add items to your existing loans" }) })
              ] })
            ] }),
            !s.hideLoanFilter && /* @__PURE__ */ e.jsx(ge, {})
          ]
        }
      ),
      /* @__PURE__ */ e.jsx(
        o,
        {
          onClick: () => s.setActiveMobileTab("LOANS"),
          as: "div",
          variant: s.activeMobileTab === "LOANS" ? "blue" : "primary",
          className: L("flex-1 py-2 border-b-2", {
            "border-blue-100": s.activeMobileTab === "LOANS",
            "border-transparent": s.activeMobileTab !== "LOANS"
          }),
          children: "Loan"
        }
      )
    ] }),
    s.activeMobileTab === "LOANS" && !s.hideLoanFilter && /* @__PURE__ */ e.jsx("div", { className: "flex-1 flex-row px-4 pt-4", children: /* @__PURE__ */ e.jsx(
      ce,
      {
        active: s.selectedLoansFilter,
        onFilterChange: s.setLoansFilter
      }
    ) })
  ] });
}, hs = () => /* @__PURE__ */ e.jsxs("div", { className: "relative flex-1 gap-3 flex items-center justify-center flex-col", children: [
  /* @__PURE__ */ e.jsx(
    b,
    {
      size: "lg",
      rounded: "md",
      children: /* @__PURE__ */ e.jsx(b.Fallback, { className: "p-1", children: /* @__PURE__ */ e.jsx(c, { strokeWidth: 2, name: "Box", width: 16, height: 16 }) })
    }
  ),
  /* @__PURE__ */ e.jsx(o, { variant: "inherit", size: "sm", children: "No shoebox item" }),
  /* @__PURE__ */ e.jsx(o, { size: "sm", variant: "secondary", children: "Nothing to move or add." })
] }), fe = (s, l) => l.includes(s.ownerUserId), xs = (s) => {
  const l = T(), { attributes: a, listeners: t, isDragging: m, setNodeRef: f } = De({
    id: s.file.id,
    data: s.file
  }), u = l.isFileChecked(s.file), h = [s.file, ...l.checkedFiles.filter((d) => d.id !== s.file.id)];
  return /* @__PURE__ */ e.jsx(
    Qe,
    {
      file: s.file,
      checked: u,
      onCheckFilesFromOrToFile: (d) => l.checkFilesFromOrToFile(d),
      isDragging: m,
      onMove: () => l.setMoveToLoanShoeBoxFiles(h),
      onDelete: () => l.setStagedFilesForDeletion(h),
      onRename: (d) => l.setRenameShoeBoxFile(d),
      onOpenInNewTab: (d) => l.openFileInNewTab(d),
      onClick: (d) => l.openFileDoubleClick(d),
      onCheckChange: (d, p) => d ? l.checkFile(p) : l.unCheckFile(p),
      onCancelUpload: (d) => l.cancelUploadingFile(d),
      ...a,
      ...t,
      ref: f
    }
  );
}, Se = (s) => {
  var l, a;
  return /* @__PURE__ */ e.jsxs(
    w.Item,
    {
      value: s.folder.key,
      children: [
        /* @__PURE__ */ e.jsx(
          w.Trigger,
          {
            asChild: !0,
            className: L("flex items-start gap-2 overflow-hidden cursor-pointer "),
            children: /* @__PURE__ */ e.jsxs(_, { space: "xs", className: "overflow-hidden flex-1", children: [
              /* @__PURE__ */ e.jsxs("div", { className: "flex items-center overflow-hidden gap-2 flex-1", children: [
                /* @__PURE__ */ e.jsx(
                  D,
                  {
                    "aria-label": "Files Count",
                    variant: s.folder.isSubFolder ? "secondary" : "warning",
                    className: "w-5 text-xxs aspect-square h-5 flex items-center justify-center",
                    children: s.folder.filesCount
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  o,
                  {
                    size: "sm",
                    weight: "medium",
                    as: "div",
                    truncate: !0,
                    className: "overflow-hidden",
                    children: s.folder.owner === "ME" ? /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                      s.folder.senderGivenName,
                      /* @__PURE__ */ e.jsx(o, { as: "span", variant: "secondary", size: "sm", children: " (Me)" })
                    ] }) : s.folder.senderName
                  }
                ),
                s.folder.subFolders.length === 0 && /* @__PURE__ */ e.jsxs(x, { children: [
                  /* @__PURE__ */ e.jsx(x.Trigger, { children: /* @__PURE__ */ e.jsx(
                    c,
                    {
                      name: "InfoEmpty",
                      className: L({
                        "text-gray-neutral-70": s.folder.loans.length === 0,
                        "text-primary": s.folder.loans.length > 0
                      }),
                      width: 14,
                      height: 14,
                      strokeWidth: 1.5
                    }
                  ) }),
                  /* @__PURE__ */ e.jsx(
                    x.Content,
                    {
                      align: "start",
                      side: "bottom",
                      alignOffset: 0,
                      className: "p-2 max-w-52",
                      children: /* @__PURE__ */ e.jsxs(_, { row: !0, space: "xs", children: [
                        /* @__PURE__ */ e.jsx(
                          c,
                          {
                            className: L({
                              "text-gray-neutral-70": s.folder.loans.length === 0,
                              "text-primary": s.folder.loans.length > 0
                            }),
                            name: "InfoEmpty",
                            width: 14,
                            height: 14,
                            strokeWidth: 1.5
                          }
                        ),
                        /* @__PURE__ */ e.jsxs(
                          _,
                          {
                            space: "xs",
                            className: "overflow-hidden",
                            children: [
                              /* @__PURE__ */ e.jsxs(o, { size: "xs", children: [
                                s.folder.loans.length > 0 ? `User is on ${s.folder.loans.length} active loans` : "User external to mysherpas",
                                "."
                              ] }),
                              /* @__PURE__ */ e.jsx(
                                o,
                                {
                                  italic: !0,
                                  variant: "secondary",
                                  size: "xs",
                                  children: "Sent via Secure Upload"
                                }
                              ),
                              s.folder.loans.map((t) => /* @__PURE__ */ e.jsx(
                                v.Close,
                                {
                                  asChild: !0,
                                  children: /* @__PURE__ */ e.jsxs(
                                    o,
                                    {
                                      onClick: () => s.onGoToLoan(t.id),
                                      truncate: !0,
                                      size: "xs",
                                      className: "cursor-pointer hover:underline text-primary-500",
                                      children: [
                                        t.shortCode,
                                        " : ",
                                        t.projectName
                                      ]
                                    }
                                  )
                                },
                                t.shortCode
                              ))
                            ]
                          }
                        )
                      ] })
                    }
                  )
                ] }),
                s.folder.messages.length > 0 && /* @__PURE__ */ e.jsx(
                  "div",
                  {
                    className: "flex items-center",
                    role: "presentation",
                    tabIndex: 0,
                    onKeyUp: (t) => t.stopPropagation(),
                    onClick: (t) => t.stopPropagation(),
                    children: /* @__PURE__ */ e.jsxs(v, { children: [
                      /* @__PURE__ */ e.jsxs(x, { children: [
                        /* @__PURE__ */ e.jsx(
                          x.Trigger,
                          {
                            asChild: !0,
                            children: /* @__PURE__ */ e.jsxs(
                              _,
                              {
                                as: "button",
                                "aria-label": "Messages Count",
                                row: !0,
                                space: "xs",
                                children: [
                                  /* @__PURE__ */ e.jsx(o, { size: "xs", children: s.folder.messages.length }),
                                  /* @__PURE__ */ e.jsx(c, { name: "MessageText", width: 18, height: 18 })
                                ]
                              }
                            )
                          }
                        ),
                        /* @__PURE__ */ e.jsxs(
                          x.Content,
                          {
                            align: "start",
                            side: "bottom",
                            alignOffset: 0,
                            className: "flex flex-row gap-2 p-1 px-2 items-center",
                            children: [
                              s.folder.messages.length,
                              " ",
                              s.folder.messages.length > 1 ? "messages" : "message",
                              ". ",
                              /* @__PURE__ */ e.jsx(
                                v.Trigger,
                                {
                                  asChild: !0,
                                  children: /* @__PURE__ */ e.jsx(o, { size: "sm", variant: "blue", underline: !0, children: "Click to View" })
                                }
                              )
                            ]
                          }
                        )
                      ] }),
                      /* @__PURE__ */ e.jsxs(v.Content, { className: "sm:max-w-2xl", children: [
                        /* @__PURE__ */ e.jsxs(_, { row: !0, space: "sm", className: "items-center px-4 pt-3", children: [
                          /* @__PURE__ */ e.jsx(c, { name: "MessageText", width: 18, height: 18 }),
                          /* @__PURE__ */ e.jsx(o, { size: "sm", weight: "medium", as: "div", truncate: !0, className: "overflow-hidden flex-1", children: s.folder.owner === "ME" ? "me" : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                            s.folder.senderName,
                            /* @__PURE__ */ e.jsx(o, { size: "xs", as: "span", className: "text-gray-neutral-70 pl-2 flex-1", children: s.folder.senderEmailAddress })
                          ] }) }),
                          /* @__PURE__ */ e.jsx(
                            v.Close,
                            {
                              asChild: !0,
                              children: /* @__PURE__ */ e.jsx(
                                j,
                                {
                                  "aria-label": "Close Messages",
                                  size: "sm",
                                  variant: "ghost",
                                  children: /* @__PURE__ */ e.jsx(c, { name: "Cancel", width: 20, height: 20, strokeWidth: 1.5 })
                                }
                              )
                            }
                          )
                        ] }),
                        /* @__PURE__ */ e.jsx(K, {}),
                        /* @__PURE__ */ e.jsx(
                          "section",
                          {
                            "aria-label": "Messages List",
                            className: "flex flex-col gap-4 py-4 px-8 scrollbar-stable overflow-y-auto max-h-96",
                            children: s.folder.messages.map((t, m) => /* @__PURE__ */ e.jsx(o, { size: "sm", className: "p-4 rounded-md border leading-6", as: "div", children: t }, m))
                          }
                        )
                      ] })
                    ] })
                  }
                )
              ] }),
              s.folder.senderName && /* @__PURE__ */ e.jsx(o, { size: "xs", as: "span", className: "text-gray-neutral-70 pl-2 flex-1 ml-5", children: s.folder.senderEmailAddress })
            ] })
          }
        ),
        /* @__PURE__ */ e.jsxs(w.Content, { className: "p-0", children: [
          /* @__PURE__ */ e.jsx("div", { className: "flex flex-col gap-4", children: s.folder.files.map((t) => /* @__PURE__ */ e.jsx(xs, { file: t }, t.id)) }),
          /* @__PURE__ */ e.jsx(
            w,
            {
              className: "divide-y",
              collapsible: !0,
              type: "single",
              defaultValue: ((a = (l = s.folder.subFolders) == null ? void 0 : l[0]) == null ? void 0 : a.key) ?? "",
              children: s.folder.subFolders.map((t) => /* @__PURE__ */ e.jsx(
                Se,
                {
                  folder: t,
                  onGoToLoan: s.onGoToLoan
                },
                t.key
              ))
            }
          )
        ] })
      ]
    },
    s.folder.key
  );
}, ms = () => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
  /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-10" }),
  /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-10" }),
  /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-10" })
] }), je = () => {
  var m, f;
  const s = T(), l = F(
    (u, h) => {
      s.uploadFilesToShoeBox(u, h.map((d) => d.file));
    },
    [s]
  ), a = Y({
    accept: J,
    onDrop: l,
    multiple: !0
  }), t = s.folders.filter((u) => u.filesCount > 0).filter((u) => fe(u, s.teamUserIds));
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      role: "button",
      tabIndex: 0,
      ...a.getRootProps(),
      onClick: (u) => u.preventDefault(),
      onKeyUp: (u) => u.preventDefault(),
      "data-ui": s.activeMobileTab === "SHOEBOX" ? "active" : "",
      className: `flex-1 relative sm:col-span-5 md:col-span-4 xl:col-span-3 
      hidden data-active:flex
      border-r border-gray-neutral-80 sm:flex flex-col overflow-hidden`,
      children: [
        /* @__PURE__ */ e.jsx(
          "input",
          {
            ...a.getInputProps(),
            "data-testid": "lender-shoebox-sidebar-drop-target-input"
          }
        ),
        /* @__PURE__ */ e.jsx(
          o,
          {
            "data-ui": a.isDragActive ? "active" : "",
            size: "sm",
            as: "div",
            weight: "medium",
            className: "absolute overflow-hidden px-4 items-center justify-center flex text-blue-100 z-10 text-right opacity-0 w-0 h-0 data-active:w-full data-active:h-full data-active:opacity-100 inset-0 bg-blue-50 bg-opacity-50",
            children: /* @__PURE__ */ e.jsx("div", { className: "p-2 rounded-md bg-blue-50", children: "DROP FILES HERE" })
          }
        ),
        /* @__PURE__ */ e.jsxs("div", { className: "hidden sm:flex bg-white p-3  flex-col gap-8 border-b border-gray-neutral-80", children: [
          /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(v.Close, { asChild: !0, children: /* @__PURE__ */ e.jsxs(j, { size: "sm", variant: "secondary", children: [
            /* @__PURE__ */ e.jsx(c, { name: "NavArrowLeft", width: 18, height: 18, strokeWidth: 1.5 }),
            /* @__PURE__ */ e.jsx(o, { size: "sm", className: "hidden sm:inline-flex", children: "Back" })
          ] }) }) }),
          /* @__PURE__ */ e.jsxs(o, { as: "div", className: "gap-2 items-center hidden sm:flex", children: [
            "My ShoeBox",
            /* @__PURE__ */ e.jsx(x.Provider, { children: /* @__PURE__ */ e.jsxs(x, { children: [
              /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx("button", { children: /* @__PURE__ */ e.jsx(
                c,
                {
                  strokeWidth: 1.5,
                  name: "InfoEmpty",
                  width: 16,
                  height: 16
                }
              ) }) }),
              /* @__PURE__ */ e.jsxs(
                x.Content,
                {
                  align: "start",
                  side: "bottom",
                  alignOffset: 0,
                  className: "p-4 flex gap-3 z-dialog w-80",
                  children: [
                    /* @__PURE__ */ e.jsx(b, { size: "xs", className: "rounded-md", children: /* @__PURE__ */ e.jsx(b.Fallback, { className: "p-0 bg-blue-100 text-white ", children: /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }),
                    /* @__PURE__ */ e.jsxs("div", { className: "flex flex-col gap-2", children: [
                      /* @__PURE__ */ e.jsx(o, { size: "sm", weight: "medium", children: "My Shoebox" }),
                      /* @__PURE__ */ e.jsx(o, { variant: "inherit", size: "sm", children: "Upload to your shoebox and select/drag to add items to your existing loan" })
                    ] })
                  ]
                }
              )
            ] }) }),
            !s.hideLoanFilter && /* @__PURE__ */ e.jsx(ge, {})
          ] })
        ] }),
        /* @__PURE__ */ e.jsx("div", { className: "mt-5 px-4 min-h-10", children: s.totalCheckedFiles > 0 && /* @__PURE__ */ e.jsxs("div", { className: "bg-gray-neutral-30 px-3 rounded-full flex items-center gap-2", children: [
          /* @__PURE__ */ e.jsx(
            j,
            {
              onClick: s.unCheckAllFiles,
              className: "p-0",
              variant: "ghost",
              children: /* @__PURE__ */ e.jsx(c, { name: "Cancel", width: 18, height: 18 })
            }
          ),
          /* @__PURE__ */ e.jsxs(o, { size: "xs", className: "flex-1", children: [
            s.totalCheckedFiles,
            " Selected"
          ] }),
          /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              j,
              {
                onClick: () => s.setMoveToLoanShoeBoxFiles(s.checkedFiles),
                variant: "ghost",
                className: "p-1",
                children: /* @__PURE__ */ e.jsx(
                  c,
                  {
                    name: "FolderMoveUp",
                    strokeWidth: 2,
                    width: 20,
                    height: 20
                  }
                )
              }
            ) }),
            /* @__PURE__ */ e.jsx(x.Content, { align: "start", side: "bottom", alignOffset: 0, children: "Move" })
          ] }),
          /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              j,
              {
                onClick: () => s.setStagedFilesForDeletion(s.checkedFiles),
                variant: "ghost",
                className: "p-1",
                children: /* @__PURE__ */ e.jsx(c, { name: "Trash", strokeWidth: 2, width: 20, height: 20 })
              }
            ) }),
            /* @__PURE__ */ e.jsx(x.Content, { align: "start", side: "bottom", alignOffset: 0, children: "Delete" })
          ] })
        ] }) }),
        !s.loadingShoeBoxFiles && t.length === 0 && /* @__PURE__ */ e.jsx(hs, {}),
        !s.loadingShoeBoxFiles && t.length > 0 && /* @__PURE__ */ e.jsx("div", { className: "flex-1 w-full py-4 sm:py-8 sm:pt-5 sm:pb-8 px-4  overflow-y-scroll scrollbar-stable", children: /* @__PURE__ */ e.jsx(
          w,
          {
            collapsible: !0,
            className: "divide-y",
            type: "single",
            defaultValue: ((f = (m = s.folders) == null ? void 0 : m[0]) == null ? void 0 : f.key) ?? "",
            children: t.map(
              (u) => /* @__PURE__ */ e.jsx(
                Se,
                {
                  folder: u,
                  onGoToLoan: s.goToLoan
                },
                u.key
              )
            )
          }
        ) }),
        s.loadingShoeBoxFiles && /* @__PURE__ */ e.jsx("div", { className: " flex flex-col w-full gap-4 p-4", children: /* @__PURE__ */ e.jsx(ms, {}) }),
        s.secureUploadPageLink && /* @__PURE__ */ e.jsx("div", { className: "shrink-0 p-4", children: /* @__PURE__ */ e.jsx(j, { asChild: !0, variant: "outline", className: "space-x-2 w-full", children: /* @__PURE__ */ e.jsxs("div", { className: "hover:bg-white overflow-hidden", children: [
          /* @__PURE__ */ e.jsx(c, { name: "Lock", width: 15, height: 15 }),
          " ",
          /* @__PURE__ */ e.jsx(o, { truncate: !0, size: "sm", className: "flex-1", children: "My Secure Upload Page Link" }),
          " ",
          /* @__PURE__ */ e.jsx(We, { text: s.secureUploadPageLink })
        ] }) }) })
      ]
    }
  );
};
je.displayName = "GlobalShoeSidebar";
const us = () => {
  const [s, l] = Q("GRID"), [a, t] = Q(), m = T(), f = (E) => {
    const { active: C } = E, { data: k } = C, { current: O } = k;
    t(O);
  }, u = (E) => {
    const { over: C, active: k } = E;
    if (!C || !k) return;
    const { data: O } = k, { data: G } = C, { current: P } = G, { current: B } = O, U = m.checkedFiles.filter(
      (V) => V.id !== (B == null ? void 0 : B.id)
    );
    t(void 0), m.setDroppedShoeBoxFiles(
      P,
      [...U, B]
    );
  }, h = W(Be, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10
    }
  }), d = W(Me, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10
    }
  }), p = W(Re, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  }), S = Oe(h, d, p);
  return {
    dragItem: a,
    sensors: S,
    displayView: s,
    onDisplayViewChange: l,
    onDragStart: f,
    onDragEnd: u,
    totalCheckedFiles: m.totalCheckedFiles,
    loadingShoeBoxFiles: m.loadingShoeBoxFiles
  };
}, gs = () => {
  var a;
  const s = us(), l = T();
  return ie(() => () => {
    l.onSetLoansFilterQuery("");
  }, [l.onSetLoansFilterQuery]), /* @__PURE__ */ e.jsxs(
    ze,
    {
      modifiers: [ls, ss],
      sensors: s.sensors,
      onDragStart: s.onDragStart,
      onDragEnd: s.onDragEnd,
      children: [
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            "data-testid": "global-shoe-box-content",
            className: "flex flex-col sm:grid sm:grid-cols-12 h-full w-full bg-white rounded-md overflow-hidden",
            children: [
              /* @__PURE__ */ e.jsx(cs, {}),
              /* @__PURE__ */ e.jsx(je, {}),
              /* @__PURE__ */ e.jsxs(
                "div",
                {
                  "data-ui": l.activeMobileTab === "LOANS" ? "active" : "",
                  className: "col-span-1 data-active:flex-1 sm:col-span-7 md:col-span-8 xl:col-span-9 bg-white-neutral sm:bg-black-10 flex flex-col overflow-hidden",
                  children: [
                    /* @__PURE__ */ e.jsx(ue, {}),
                    /* @__PURE__ */ e.jsx(ds, {}),
                    /* @__PURE__ */ e.jsx(me, {})
                  ]
                }
              )
            ]
          }
        ),
        ((a = s.dragItem) == null ? void 0 : a.id) && /* @__PURE__ */ e.jsx(Ge, { children: /* @__PURE__ */ e.jsx(
          Pe,
          {
            className: "w-40",
            type: "FILE",
            title: s.dragItem.title,
            documentName: s.dragItem.documentName,
            count: s.totalCheckedFiles
          }
        ) })
      ]
    }
  );
}, fs = () => {
  const s = T(), l = s.stagedFilesForDeletion.length > 1, [a] = s.stagedFilesForDeletion;
  return /* @__PURE__ */ e.jsx(
    Xe,
    {
      ariaLabel: "Delete ShoeBox File Confirmation",
      onOpenChange: (t) => !t && s.setStagedFilesForDeletion([]),
      open: s.stagedFilesForDeletion.length > 0,
      onConfirm: () => {
        s.deleteShoeBoxItems(s.stagedFilesForDeletion);
      },
      message: `Are you sure you want to delete ${l ? `${s.stagedFilesForDeletion.length} items` : a == null ? void 0 : a.title}`,
      confirmButtonText: "Delete"
    }
  );
}, Ss = () => {
  const s = T();
  return /* @__PURE__ */ e.jsx(
    Ve,
    {
      items: s.moveToLoanShoeBoxFiles,
      open: s.moveToLoanShoeBoxFiles.length > 0,
      onOpenChange: (l) => {
        l || s.setMoveToLoanShoeBoxFiles([]);
      },
      hideLoanFilter: s.hideLoanFilter,
      loans: s.loans,
      onMoveConfirm: (l) => {
        s.setPickLenderLoan(
          l,
          s.moveToLoanShoeBoxFiles
        );
      }
    }
  );
}, js = () => {
  const s = T(), l = s.rejectedFiles.length > 1, [a] = s.rejectedFiles;
  return /* @__PURE__ */ e.jsx(
    v,
    {
      open: s.rejectedFiles.length > 0,
      onOpenChange: (t) => {
        t || s.setRejectedFiles([]);
      },
      children: /* @__PURE__ */ e.jsxs(v.Content, { className: "pr-6 pt-3 pl-4 pb-4 gap-3 flex-row flex", children: [
        /* @__PURE__ */ e.jsx(c, { name: "InfoCircleSolid", width: 24, height: 24, className: "text-destructive" }),
        /* @__PURE__ */ e.jsxs("div", { className: "flex flex-col flex-1 gap-2", children: [
          /* @__PURE__ */ e.jsxs(o, { size: "sm", weight: "medium", children: [
            "Error Uploading ",
            l ? `${s.rejectedFiles.length} items` : a == null ? void 0 : a.name
          ] }),
          /* @__PURE__ */ e.jsx(o, { size: "sm", children: "Unable to upload file because file type is not supported" }),
          /* @__PURE__ */ e.jsx(o, { size: "xs", variant: "secondary", children: "Only .jpg/jpeg/png, .mp4, .mp3, .pdf, .doc, .zip, .pdf, .txt files supported" }),
          l && /* @__PURE__ */ e.jsx("div", { className: "overflow-y-scroll scrollbar-stable flex-1 max-h-44 flex flex-col gap-2", children: s.rejectedFiles.map((t) => /* @__PURE__ */ e.jsx(
            o,
            {
              size: "xs",
              children: t.name
            },
            t.name
          )) })
        ] }),
        /* @__PURE__ */ e.jsx(v.Close, { asChild: !0, children: /* @__PURE__ */ e.jsx(j, { variant: "ghost", size: "sm", children: /* @__PURE__ */ e.jsx(c, { name: "Cancel", width: 20, height: 20, strokeWidth: 1.5 }) }) })
      ] })
    }
  );
}, Fs = () => {
  var l;
  const s = T();
  return /* @__PURE__ */ e.jsx(
    Ke,
    {
      open: !!s.shoeBoxFileToRename,
      onOpenChange: (a) => {
        a || s.setRenameShoeBoxFile(null);
      },
      onRename: (a) => {
        s.shoeBoxFileToRename && s.renameShoeBoxFile(s.shoeBoxFileToRename, a);
      },
      name: ((l = s.shoeBoxFileToRename) == null ? void 0 : l.title) ?? ""
    }
  );
}, Fe = we((s, l) => {
  const { size: a, ...t } = s, m = T(), f = m.folders.filter((S) => S.filesCount > 0).filter((S) => fe(S, m.teamUserIds)).reduce((S, E) => S + E.filesCount, 0), u = F(
    (S, E) => {
      m.uploadFilesToShoeBox(S, E.map((C) => C.file));
    },
    [m]
  ), h = Y({
    accept: J,
    onDrop: u,
    multiple: !0
  }), { onClick: d, ...p } = h.getRootProps();
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      ref: l,
      ...t,
      className: L("cursor-pointer gap-2 flex flex-col text-white rounded-md transition-all will-change-auto  w-full max-w-60", {
        "bg-blue-800": a === "default",
        "h-36 p-3": a === "default",
        "h-28": a === "small"
      }),
      children: [
        /* @__PURE__ */ e.jsxs("div", { className: L("flex gap-2 w-full", {
          "justify-center": a === "small"
        }), children: [
          /* @__PURE__ */ e.jsx(b, { size: "xs", className: "overflow-visible", children: /* @__PURE__ */ e.jsxs(
            b.Fallback,
            {
              className: L("p-0 relative rounded-full", {
                "bg-blue-55": a === "default",
                "bg-transparent": a === "small"
              }),
              children: [
                /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }),
                f > 0 && a === "small" && /* @__PURE__ */ e.jsx("div", { className: " w-2 aspect-square bg-yellow-60 rounded-full absolute top-0 right-0" })
              ]
            }
          ) }),
          /* @__PURE__ */ e.jsx(
            "div",
            {
              className: L("text-left flex flex-col gap-1 flex-1", {
                hidden: a === "small"
              }),
              children: /* @__PURE__ */ e.jsxs(o, { as: "div", variant: "inherit", size: "xs", weight: "medium", className: "flex items-center gap-2", children: [
                "My Shoebox ",
                /* @__PURE__ */ e.jsx(D, { variant: "warning", className: "w-4 h-4 p-0 text-[8px] justify-center font-medium", children: f })
              ] })
            }
          ),
          /* @__PURE__ */ e.jsx(x.Provider, { children: /* @__PURE__ */ e.jsxs(x, { children: [
            /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx("button", { className: L("", {
              hidden: a === "small"
            }), children: /* @__PURE__ */ e.jsx(
              c,
              {
                strokeWidth: 1.5,
                name: "InfoEmpty",
                width: 14,
                height: 14
              }
            ) }) }),
            /* @__PURE__ */ e.jsxs(
              x.Content,
              {
                align: "start",
                side: "bottom",
                alignOffset: 0,
                className: "p-4 flex gap-3 z-dialog w-80",
                children: [
                  /* @__PURE__ */ e.jsx(b, { size: "xs", className: "rounded-md", children: /* @__PURE__ */ e.jsx(b.Fallback, { className: "p-0 bg-blue-100 text-white ", children: /* @__PURE__ */ e.jsx(c, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }),
                  /* @__PURE__ */ e.jsxs("div", { className: "flex flex-col gap-2", children: [
                    /* @__PURE__ */ e.jsx(o, { size: "sm", weight: "medium", children: "My Shoebox" }),
                    /* @__PURE__ */ e.jsx(o, { variant: "inherit", size: "sm", children: "Upload to your shoebox and select/drag to add items to your existing loan" })
                  ] })
                ]
              }
            )
          ] }) })
        ] }),
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            ...p,
            className: L("p-2 flex group flex-col gap-2 items-center border-dashed border rounded-md relative overflow-hidden", {
              "aspect-square w-full flex items-center justify-center": a === "small"
            }),
            children: [
              /* @__PURE__ */ e.jsx("input", { ...h.getInputProps(), id: "shoebox-footer-dropdown-target-input" }),
              /* @__PURE__ */ e.jsx(
                c,
                {
                  className: L("", {
                    hidden: a === "default"
                  }),
                  name: "Plus",
                  width: 24,
                  height: 24
                }
              ),
              /* @__PURE__ */ e.jsx(
                o,
                {
                  variant: "inherit",
                  className: L("text-[10px]", {
                    hidden: a === "small"
                  }),
                  children: "Drag and Drop Files"
                }
              ),
              /* @__PURE__ */ e.jsx(
                o,
                {
                  variant: "inherit",
                  className: L("text-[10px]", {
                    hidden: a === "small"
                  }),
                  children: "or"
                }
              ),
              /* @__PURE__ */ e.jsx(
                o,
                {
                  as: "div",
                  onClick: (S) => {
                    S.stopPropagation(), S.preventDefault(), h.open();
                  },
                  variant: "inherit",
                  className: L("text-[10px] px-8 underline", {
                    hidden: a === "small"
                  }),
                  children: "Browse my device"
                }
              ),
              h.isDragActive && /* @__PURE__ */ e.jsx(
                o,
                {
                  size: "sm",
                  className: "absolute flex inset-0 bg-blue-10 text-blue-60  items-center justify-center transition-opacity",
                  children: "DROP FILES HERE"
                }
              )
            ]
          }
        )
      ]
    }
  );
});
Fe.displayName = "GlobalShoeBoxTriggerArea";
const Le = (s) => /* @__PURE__ */ e.jsxs($e, { ...s, children: [
  /* @__PURE__ */ e.jsxs(
    v,
    {
      open: s.open,
      onOpenChange: s.onOpenChange,
      children: [
        /* @__PURE__ */ e.jsx(v.Trigger, { asChild: !0, children: s.children }),
        /* @__PURE__ */ e.jsx(v.Content, { className: "h-screen sm:max-w-[95%]", children: /* @__PURE__ */ e.jsx(x.Provider, { children: /* @__PURE__ */ e.jsx(gs, {}) }) })
      ]
    }
  ),
  /* @__PURE__ */ e.jsx(Ze, {}),
  /* @__PURE__ */ e.jsx(qe, {}),
  /* @__PURE__ */ e.jsx(Ss, {}),
  /* @__PURE__ */ e.jsx(Fs, {}),
  /* @__PURE__ */ e.jsx(fs, {}),
  /* @__PURE__ */ e.jsx(js, {})
] });
Le.displayName = "GlobalShoeBox";
const tl = Object.assign(Le, {
  Trigger: Fe
});
export {
  tl as default
};
